import React from 'react';

import Button from '../../Button/Button'

import gsap  from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import CSSRulePlugin from "gsap/CSSRulePlugin";
import CSSPlugin from "gsap/CSSPlugin";
import SplitText from "gsap/SplitText";
import * as Scroll from 'react-scroll';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'



/**
 * PROPS PROGETTO
 * 
 */


import './TopHeaderHome.scss';

const mouseWheel = require('../../../images/wheel.png');
const swipeRight = require('../../../images/swipe-right.svg');

class TopHeaderHome extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrolled: false,
            scrolling: false,
            timeLineWords: gsap.timeline(),
            
        };

        this.modal = null;

        this.handleOnScroll = this.handleOnScroll.bind(this);



    }

    componentDidMount() {
        gsap.registerPlugin(SplitText);

        let  mySplitText =  new SplitText("#quote", { type: "words,chars" });
        let  mySplitText1 =  new SplitText("#quote-1", { type: "words,chars" })

        let  mySplitText2 =  new SplitText("#quote-2", { type: "words,chars" })
        let  mySplitText3 =  new SplitText("#quote-3", { type: "words,chars" })
        let  mySplitText4 =  new SplitText("#quote-4", { type: "words,chars" })
        let  mySplitText5 =  new SplitText("#quote-5", { type: "words,chars" })
        let  mySplitText6 =  new SplitText("#dot", { type: "words,chars" })

        let chars = mySplitText.chars;
        let chars1 = mySplitText1.chars;
        let chars2 = mySplitText2.chars;
        let chars3 = mySplitText3.chars;
        let chars4 = mySplitText4.chars;
        let chars5 = mySplitText5.chars;
        let chars6 = mySplitText6.chars;

        gsap.set("#quote-1", { perspective: 400 });
        gsap.set("#quote-2", { perspective: 400 });
        gsap.set("#quote-3", { perspective: 400 });
        gsap.set("#quote-4", { perspective: 400 });
        gsap.set("#quote-5", { perspective: 400 });
        gsap.set("#dot", { perspective: 400 });



        this.state.timeLineWords.pause();

        
        
        this.state.timeLineWords.from(chars,
            {
                duration: 0.6, opacity: 0, scale: 0.9,
                y: 80, rotationX: 8, transformOrigin: "0% 50% -50",
                ease: "back", stagger: 0.01
            }, "+=0");
        
       

        
        this.state.timeLineWords.from(chars1,
            {
                duration: 0.5, opacity: 0, scale: 0.9,
                y: 40, rotationX: 8, transformOrigin: "0% 50% -50",
                ease: "back", stagger: 0.01
        }, "<0.2");
          this.state.timeLineWords.to(chars1,
            {
                duration: 0.4, opacity: 0, scale: 0.9,
                y: -40, rotationX: 8, transformOrigin: "0% 50% -50",
                ease: "back", stagger: 0.01
        }, ">");
        this.state.timeLineWords.from(chars2,
            {
                duration: 0.4, opacity: 0, scale: 0.9,
                y: 40, rotationX: 8, transformOrigin: "0% 50% -50",
                ease: "back", stagger: 0.01
            }, ">-0.35");
            this.state.timeLineWords.to(chars2,
            {
                duration: 0.4, opacity: 0, scale: 0.9,
                y: -40, rotationX: 8, transformOrigin: "0% 50% -50",
                ease: "back", stagger: 0.01
        }, ">");
        this.state.timeLineWords.from(chars3,
            {
                duration: 0.4, opacity: 0, scale: 0.9,
                y: 40, rotationX: 8, transformOrigin: "0% 50% -50",
                ease: "back", stagger: 0.01
            }, ">-0.35");
            this.state.timeLineWords.to(chars3,
            {
                duration: 0.4, opacity: 0, scale: 0.9,
                y: -40, rotationX: 8, transformOrigin: "0% 50% -50",
                ease: "back", stagger: 0.01
        }, ">");
        this.state.timeLineWords.from(chars4,
            {
                duration: 0.4, opacity: 0, scale: 0.9,
                y: 40, rotationX: 8, transformOrigin: "0% 50% -50",
                ease: "back", stagger: 0.01
            }, ">-0.35");
            this.state.timeLineWords.to(chars4,
            {
                duration: 0.4, opacity: 0, scale: 0.9,
                y: -40, rotationX: 8, transformOrigin: "0% 50% -50",
                ease: "back", stagger: 0.01
        }, ">");

        this.state.timeLineWords.from(chars5,
            {
                duration: 0.4, opacity: 0, scale: 0.9,
                y: 40, rotationX: 8, transformOrigin: "0% 50% -50",
                ease: "back", stagger: 0.01
            }, ">-0.35");
            this.state.timeLineWords.from(chars6,
            {
                duration: 0.4, opacity: 0, scale: 0.9,
                y: 40, rotationX: 8, transformOrigin: "0% 50% -50",
                ease: "back", stagger: 0.01
        }, ">-0.35");
        this.state.timeLineWords.from('.home__background', {
           y: '100%',
           scale: 0.3,
            duration: 0.8
        }, "<0.3");
           this.state.timeLineWords.from('.logo', {
           y: -500,
           scale: 0.6,
            duration: 0.8,
            autoAlpha: 0,

            ease:'Power1.easeIn'
        }, "<");
        this.state.timeLineWords.from('.at-menu-toggler', {
            y: -500,
            scale: 0.6,
             duration: 1.2,
             autoAlpha: 0,
             ease:'Power1.easeIn'

         }, "<");
         this.state.timeLineWords.from('.cookie', {
            y:  300,
            autoAlpha: 0,
             duration: 0.5
         }, "<0.5");
         
         this.state.timeLineWords.to('.mouse-wheel', {
            autoAlpha: 1,
             duration: 0.5
         }, "<0.5");
    
     

    

        setTimeout(() => {
            this.state.timeLineWords.play();

        }, 2500);

    }

    componentWillUnmount() {

    }

    handleOnScroll(event) {

    }

    scrollDown(){
        console.log('scroll')
        let pageHeight = window.innerHeight*2;

        var Scroll = require('react-scroll');
        var scroll = Scroll.animateScroll;
         
        scroll.scrollTo(pageHeight, {
            duration: 2000,
     
          });
    }

    render() {
        const isMobile = this.state.width <= 812;

        return (
            <div className="home">


                {/*Elemento in parallax, si può usare lo stesso elemento, 
                poi cambiare il background applicando il bg
                con la classNamename bg--n
                 */}

                <section className="home__topheader">


                    <div id="cycle">
                        <div id="slide-1" className="slide">
                            <h2 id="quote">Behind<span> <span id="your" ref="toggle" data-magnetic  data-cursor="-exclusion -lg"><span id="y">y</span>our</span> <br className="mobile"></br>  <span style={{opacity: 0, width: '4px', marginLeft: '-19px'}}>&nbsp;

</span></span> 
                            <span id="quote-1" className="last-character">branding</span>
                            <span id="quote-2" className="last-character">design</span>
                            <span id="quote-3" className="last-character">apps</span>
                            <span id="quote-4" className="last-character">web</span>
                            <span id="quote-5" className="last-character">success<span id="dot">.</span></span>
                            
                            </h2>
                        </div>
                        
                        {/*
                        <div id="slide-2" className="slide">
                            <h2>Behind<span> your </span>design</h2>
                        </div>
                        <div id="slide-3" className="slide">
                            <h2>Behind<span> your </span>apps</h2>
                        </div>
                        <div id="slide-4" className="slide">
                            <h2>Behind<span> your </span>web</h2>
                        </div>
                        <div id="slide-5" className="slide">
                            <h2>Behind<span> your </span>success<span>.</span></h2>
                        </div>
                        */}
                        

                    </div>

                	{!isMobile && (
					<Button onClick={() => this.scrollDown()}  className="mouse-wheel">
						<img src={mouseWheel} alt="Mouse wheel" />
					</Button>
				) }
                </section>


            </div >

        );
    }
}

export default TopHeaderHome;
