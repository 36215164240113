import React, { useState } from 'react';
import Lottie from 'react-lottie';

import * as animationData from '../../animations/quinaryo.json';
import * as playData from '../../animations/play.json';
import { Link } from 'gatsby';
import { navigate } from 'gatsby'




const defaultOptions = {
	loop: false,
	autoplay: true,
	animationData: animationData.default,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice'
	}
};

const PLAY_OPTS = {
	loop: false,
	autoplay: true,
	animationData: playData.default,
	rendererSettings: {
		preserveAspectRatio: 'xMinYMin meet'
	}
};

const Planet = props => {
	const [stop, setStop] = useState(true);

   function goToCs(){
	   if(window.innerWidth >= 1024){
	navigate('/case-study/' + props.pageUrl);
	}

   }
   function goToCsMob(){
	navigate('/case-study/' + props.pageUrl);

   }
	return (
		<>

         
		<div id={props.id}
			className="planet"
		//	onClick={props.onClick}
			onMouseEnter={() => setStop(false)}
			onMouseLeave={() => setStop(true)}
			onClick={() => goToCs()}
			onDoubleClick={() => goToCsMob()} 
			 >

			{props.imageSrc &&
				<img
					className="planet-image"
					src={props.imageSrc}
					style={{ width: '100%' }}
					alt={props.name ? props.name : 'Pianeta'}
					onMouseEnter={props.onMouseEnter}
					onMouseLeave={props.onMouseLeave}
								/>}
			{/* <img className="preview" src={require('../../images/example.png')} /> */}
			<div
				
				style={{ 
					backgroundImage: `url(${props.gifSrc})`
				 }}
				className="preview-container">

				<Lottie options={PLAY_OPTS}
					height={'15%'}
					width={'15%'}
					isStopped={stop}

				// isStopped={this.state.isStopped}
				// isPaused={this.state.isPaused}
				/>
			</div>
			{/* <video
					className="preview"
					autoPlay="autoplay"
					src={require('../../images/cerchio-quinaryo.mp4')}
					loop={true}
					muted
					type="video/mp4"
					style={{
						width: '100%'
					}}
					onMouseEnter={props.onMouseEnter}
					onMouseLeave={props.onMouseLeave}
				/> */}
		</div>

	</>
	);
};

export default Planet;
