import React from 'react'

import Button from './Button'

import './LiftoffButton.css'

const liftoffArrow = require("../../images/arrow-up.svg");
const rocket = require("../../images/rocket.svg");

const LiftoffButton = (props) => {
    return <Button className={'liftoff-button' + (props.animated ? ' animated ' : '')} style={props.style} 
        onClick={props.onClick} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
        <img src={liftoffArrow} alt="Open modal" />
        <label>Decolla e scopri il progetto</label>
        <img src={rocket} alt="Modal rocket" />
    </Button>;
}

export default LiftoffButton;