/* JS */

/* react */
import React, { useState, useEffect, useRef } from 'react';
/* third part components */
import * as lottie from 'lottie-web';
import * as Scroll from 'react-scroll';
import { Helmet } from "react-helmet";
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

/* custom components */
import ModalVideo from 'react-modal-video'

import Hamburger from '../components/Hamburger/Hamburger';
import ATLayout from '../components/ATLayout';
import SEO from '../components/seo';
import WelcomePage from '../components/WelcomePage';
import FullPageSection from '../components/FullPage/FullPageSection';
import CaseStudyFullPage from '../components/FullPage/CaseStudyFullPage';
import ATFullPage from '../components/FullPage/ATFullPage';
// import PlanetsOrbit from '../components/Planets/PlanetsOrbit';
import PlanetsOrbit from '../components/Planets/PlanetsOrbit2';
import LinkButton from '../components/Button/LinkButton';
import BackgroundStars from '../components/HomeRestyle/BackgroundStars/BackgroundStars';
import TopHeaderHome from '../components/HomeRestyle/TopHeaderHome/TopHeaderHome';
import PlanetsContent from '../components/HomeRestyle/PlanetsContent/PlanetsContent';
import PlanetsContentMobile from '../components/HomeRestyle/PlanetsContentMobile/PlanetsContentMobile.jsx';
import LiftoffButton from '../components/Button/LiftoffButton';
import { Link } from 'gatsby';
import Footer2020 from '../components/Footer2020/Footer2020';

/* STYLE */

/* third part styles */
import 'animate.css';
/* custom styles */
import './home2.scss';

import * as roverData from '../animations/rover.json';
import Footer2 from '../components/Footer2/Footer2';
// import * as roverSoloData from '../animations/solo-rover.json';

var gsap;
var detect = require('is-client');
if (detect()) {
	gsap = require('gsap');
	let sctr = require('gsap/ScrollTrigger');
	gsap.gsap.registerPlugin(sctr);
}


/* IMAGES */
//const logotype = require('../images/logotype.svg');
const logotypeWhite = require('../images/logotype-white.png');

const footerBgTop = require('../images/footer-bg-top.png');
const footerBgBottom = require('../images/footer-2020-cut-2.png');

const logoStar = require('../images/logo-star.png');
const iconIg = require('../images/footer-icon.svg');
const iconFb = require('../images/footer-icon2.svg');
const iconLd = require('../images/footer-icon3.svg');
const navicella = require('../images/navicella.svg');

const PLANETS = [
	{
		id: 'idm',
		name: 'Web Design per Quinaryo',
		imageSrc: require('../images/planets/Group 183.svg'),
		title: 'IDM - Music Startup',
		subtitle: 'Il tuo progetto artistico è una startup',
		gifSrc: require('../../../gif/IDM.gif'),
		pageUrl: 'idm',
		class: "title--lg"
	},
	{
		id: 'neko',
		name: 'Web Design per Quinaryo',
		imageSrc: require('../images/planets/Group 166.svg'),
		title: 'Neko',
		subtitle: 'Un\'osmosi tra oriente e sud del mondo',
		gifSrc: require('../../../gif/Neko.gif'),
		pageUrl: 'neko',
		class: "title--lg"

	},
	/*
	{
		id: 'osservatorio',
		name: 'Web Design per Quinaryo',
		imageSrc: require('../images/planets/Group 159.svg'),
		title: 'Ragioneria dello stato & Eurispes',
		subtitle: 'Raccolta, analisi e diffusione per la ripresa',
		gifSrc: require('../../../gif/Osservatorio.gif'),
		pageUrl: 'osservatorio',
		class: "title--md"
	}, */
	{
		id: 'tedxluiss',
		name: 'Web Design per TEDxLuiss',
		imageSrc: require('../images/planets/new/Pianeta_new_ats_1.svg'),
		title: 'TEDxLuiss',
		subtitle: 'Reagire alla tremenda sconfitta della consuetudine',
		gifSrc: require('../../../gif/Tedx_edit.gif'),
		pageUrl: 'tedxluiss',
		class: "title--sm"


	},
	{
		id: 'molendini',
		name: 'Web App Design per Sushi-ii',
		imageSrc: require('../images/planets/new/Pianeta_new_ats_2.svg'),
		title: 'Molendini',
		subtitle: 'La cucina italiana al massimo livello',
		gifSrc: require('../../../gif/Molendini.gif'),
		pageUrl: 'molendini',
		class: "title--lg"


	},
	{
		id: 'molendini',
		name: 'Brand Identity per Molendini',
		imageSrc: require('../images/planets/new/Pianeta_new_ats_3.svg'),
		title: 'To&From',
		subtitle: 'Fai e ricevi il regalo che vuoi',
		gifSrc: require('../../../gif/Apparente_edit.gif'),
		pageUrl: 'toefrom',
		class: "title--lg"


	},
	{
		id: 'molendini',
		name: 'Brand Identity per Molendini',
		imageSrc: require('../images/planets/new/Pianeta_new_ats_4.svg'),
		title: 'Quinaryo',
		subtitle: 'Acquisto e vendita di titoli in criptovaluta',
		gifSrc: require('../../../gif/Quinario_edit.gif'),
		pageUrl: 'quinaryo',
		class: "title--lg"
	},
	{
		id: 'molendini',
		name: 'Brand Identity per Molendini',
		imageSrc: require('../images/planets/new/Pianeta_new_ats_5.svg'),
		title: 'Apparente',
		subtitle: 'Tra proprietari e inquilini, tutto in un\'app',
		gifSrc: require('../../../gif/Apparente_edit.gif'),
		pageUrl: 'apparente',
		class: "title--md"
	},


	{
		id: 'molendini',
		name: 'Brand Identity per Molendini',
		imageSrc: require('../images/planets/new/Pianeta_new_ats_6.svg'),
		title: 'Nerarium',
		subtitle: 'Emozionare con oggetti di design',
		gifSrc: require('../../../gif/Nerarium.gif'),
		pageUrl: 'nerarium',
		class: "title--lg"
	},
	{
		id: 'molendini',
		name: 'Brand Identity per Molendini',
		imageSrc: require('../images/planets/new/Pianeta_new_ats_7.svg'),
		title: 'Sushi-ii',
		subtitle: 'Qualità ed innovazione dietro ad una nuova identità',
		gifSrc: require('../../../gif/Sushi-ii.gif'),
		pageUrl: 'sushi-ii',
		class: "title--sm"
	},
];

var observer, roverAnim;
var tlShip, tlButton, tlLabel;
let controller;

const SystemPage = props => {

	const [fullpage, setFullpage] = useState(null);
	const [isOpen, setOpen] = useState(false)

	const [menuOpen, setMenuOpen] = useState(false);
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
	const [section, setSection] = useState(0);
	const [rotation, setRotation] = useState(-90);
	const [isMobile, setIsMobile] = useState(false);

	const myRef = useRef(null);
	const executeScroll = () => myRef.current.scrollIntoView()

	function moveShip() {
		if (tlShip && tlButton && tlLabel) {
			tlShip.play();
			tlButton.play();
			tlLabel.play();
		}
	}
	function resetShip() {
		if (tlShip && tlButton && tlLabel) {
			tlShip.reverse();
			tlButton.reverse();
			tlLabel.reverse();
		}
	}

	useEffect(() => {
		setIsMobile(window.innerWidth <= 900);

		document.body.style = 'overflow-y: hidden';


		setTimeout(() => (document.body.style = 'overflow-y: scroll'), 7000);
		handleOnWindowResize();
		window.addEventListener('resize', handleOnWindowResize);



		return function cleanup() {
			window.removeEventListener('resize', handleOnWindowResize);
			// observer.disconnect();
		};
	}, []);

	useEffect(() => {

		window.scrollTo(0, 0)
	}, [])

	var count = 0;
	function handleOnWindowResize() {
		if (count > 0) {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			} else {
				if (window.innerWidth >= 700) {
					window.location.reload();
					window.scrollTo(0, 0);
				}

			}

		}
		console.log(count);
		count++;
		setDimensions({ width: window.innerWidth, height: window.innerHeight });
	}

	// function handleOnFPMutate(mutations) {
	// 	let self = this;
	// 	mutations.forEach(function(mutation) {
	// 		if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
	// 			// console.log('attributes changed');
	// 			// console.log(mutation.target.style.transform.split(',')[1]);
	// 			setTranslateY(mutation.target.style.transform.split(',')[1]);
	// 			// console.log(self.state);
	// 		}
	// 	});
	// }

	function handleOnHamburgerClick(event) {
		setMenuOpen(!menuOpen);
	}

	function handleOnOutsideMenuClick(event) {
		setMenuOpen(false);
	}

	function handleOnPageChange(origin, destination, direction) {
		// console.log(origin, destination, direction)
		console.log(destination)
		setSection(destination.index);
		setRotation(rotation + 90 * (direction === 'up' ? -1 : 1));


	}

	function openShowReel() {
	}

	function handleOnChiSiamo(event) {
		executeScroll();

		console.log('test');

	}

	function handleOnBackToSpace() {
		if (fullpage) fullpage.moveSectionUp();
	}

	function handleOnGoToFooter() {
		if (fullpage) fullpage.moveSectionDown();
	}

	// const planets = PLANETS.map((p, i) => (
	// 	<Draggable key={i} axis="y">
	// 		<div id={'planet-' + i} className="planet-image" />
	// 	</Draggable>
	// ));

	let planets = PLANETS;

	planets = PLANETS;



	return (
		<>
			<SEO
				title="Alfatauri Studio • Web Design a Roma"
				relPath="/"
				description="Alfatauri Studio è una web agency con sede a Roma, che si occupa di comunicazione, design e sviluppo app e software per agevolare la strada verso il successo."
			/>
			<ATLayout title="Home" isMenuOpen={menuOpen} onOutsideMenuClick={handleOnOutsideMenuClick}>

				<div id="at-page" className={'at-page at-page-2020'}>
					<h1 className="mb-0">

						<a href="/home">
							<img className="logo" src={logotypeWhite} alt="Alfatauri studio web agency" title="Alfatauri studio" />
						</a>
					</h1>

					{/* Show Reel ATS */}
					<ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="cSel9iy7PIQ" onClose={() => setOpen(false)} />

					{/* Background stellato ATS */}
					<BackgroundStars></BackgroundStars>

					{/* Prima sezione Home con animazione scritte*/}
					<TopHeaderHome>
					</TopHeaderHome>

					{/* Sezione pianeti desktop*/}


					{/* Sezione pianeti mobile*/}


					{isMobile ?
						<PlanetsContentMobile
							handleOnChiSiamo={handleOnChiSiamo}
							planets={planets}
							cx={dimensions.width ? dimensions.width : null}
							cy={dimensions.height ? dimensions.height / 2 : null}
							radius={dimensions.height ? (dimensions.height * 2) / 3.5 : null}>

						</PlanetsContentMobile> :
						<PlanetsContent
							handleOnChiSiamo={handleOnChiSiamo}
							planets={planets}
							cx={dimensions.width ? dimensions.width : null}
							cy={dimensions.height ? dimensions.height / 2 : null}
							radius={dimensions.height ? (dimensions.height * 2) / 3.5 : null}>

						</PlanetsContent>

					}




					<div id="last-section-content" className="last-section-content">

						<div className="container">
							<div className="row">
								<div className="col-11">
									<h2 ref={myRef} id="ls-title" className="mb-5">
										Chi siamo<span className="orange">.</span>
									</h2>

									<p id="ls-sub" className="mb-4">
										Alfatauri è una web agency che basa la sua visione sulla raccolta di <b>idee</b> ed <b>esigenze</b>,{' '}
										<b>valutandole</b> ed <b>analizzandole</b> attentamente, con l'obiettivo di arrivare ad un processo utile all'identificazione dei servizi di cui la vostra realtà ha realmente bisogno.
									</p>
									<p id="ls-sub2" className="mb-5 pr-4">
										Crediamo che la collaborazione tra addetti ai lavori, designer e sviluppatori sia<br />
										essenziale per creare bei prodotti digitali che funzionano bene e che soprattutto<br />
										<b>migliorino la vita di persone e imprese.</b>
									</p>
									<div onClick={() => setOpen(true)} id="ls-buttons" className="d-flex align-items-center">




										<LinkButton className="mr-5 mt-5" >
											Vedi la Demo{' '}
											<svg xmlns="http://www.w3.org/2000/svg" width="16.943" height="10.43" viewBox="0 0 16.943 10.43">
												<defs>
													<clipPath id="clip-path">
														<path
															id="Tracciato_686"
															data-name="Tracciato 686"
															d="M1.53-5.35H14.72L12.37-2.41a1,1,0,0,0-.213.738A1,1,0,0,0,12.53-1a1,1,0,0,0,1.4-.16l3.28-4.09a1.2,1.2,0,0,0,0-1.5l-3.28-4.09a1,1,0,0,0-1.4-.16,1,1,0,0,0-.373.672,1,1,0,0,0,.213.738l1.8,2.24H1.53a1,1,0,0,0-1,1A1,1,0,0,0,1.53-5.35Z"
															fill="#a7a7a7"
														/>
													</clipPath>
													<clipPath id="clip-path-2">
														<path
															id="Tracciato_685"
															data-name="Tracciato 685"
															d="M-248,302H634V-1528H-248Z"
															fill="#a7a7a7"
														/>
													</clipPath>
												</defs>
												<g
													id="Raggruppa_519"
													data-name="Raggruppa 519"
													transform="translate(-0.53 11.215)"
													clipPath="url(#clip-path)"
												>
													<g id="Raggruppa_518" data-name="Raggruppa 518" clipPath="url(#clip-path-2)">
														<path
															id="Tracciato_684"
															data-name="Tracciato 684"
															d="M-4.47-16.215H22.473V4.215H-4.47Z"
															fill="#a7a7a7"
														/>
													</g>
												</g>
											</svg>
										</LinkButton>

									</div>
								</div>
							</div>
						</div>

					</div>
					<Footer2 />

					<Hamburger open={menuOpen} onClick={handleOnHamburgerClick} />
				</div>
			</ATLayout>
		</>
	);
};

export default SystemPage;
