import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import {
    ScrollTrigger,
    Draggable,
    MotionPathPlugin,
    ScrollToPlugin,
    TweenMax
} from "gsap/all";

import Planet from '../../Planets/Planet2';

import './PlanetsContent.scss';

import { Link, navigate } from 'gatsby';
import LiftoffButton from '../../Button/LiftoffButton';

const leftArrow = require('../../../images/planets/new/nav_sx.svg');
const arrowBottom = require('../../../images/planets/new/frecciagiu.svg');


var gsap;
var detect = require('is-client');
if (detect()) {
    gsap = require('gsap');
    gsap.gsap.registerPlugin(ScrollTrigger, Draggable, MotionPathPlugin, ScrollToPlugin);
}

var timeLinePlanets;

function splitCircumference(cx, cy, r, n) {
    let coordinates = [];

    for (let i = 1; i <= n; i++) {
        let radians = ((2 * Math.PI) / n) * i;
        let endX = cx + r * Math.cos(radians);
        let endY = cy - r * Math.sin(radians);
        coordinates.push({
            x: Math.round(endX),
            y: Math.round(endY),
        });
    }

    return coordinates;
}






const PlanetsContent = props => {
    const [center, setCenter] = useState({ x: 0, y: 0 });
    const [radius, setRadius] = useState(100);
    const [navigatorSelected, setNavigator] = useState(1);
    const [showFp, setFp] = useState(false);
    const [underLineWidth, setUnderLineWidth] = useState('0%');

    let planets = props.planets;
    const [urlCs, setUrlCs] = useState(planets[0].url);

    /***
 * Animazione Apertura
 */
    function animateFirstSection() {
        /**
     * Timeline di apertura del sito
     * INIZIO PRIMA TIMELINE
     */
        let timeLineBefore = null;

        if (window.innerWidth >= 1024) {
            timeLineBefore = new gsap.TimelineMax({
                scrollTrigger: {
                    trigger: '.home__topheader',
                    start: 'top top',
                    end: 'bottom top',
                    pinSpacing: false,
                    scrub: 1,
                    onUpdate: self => {

                    },
                },
            })


        }


        timeLineBefore.to('#quote', {
            y: -300,
            autoAlpha: 0,
            ease: "power1.inOut",
        }, '<');
        timeLineBefore.to('.mouse-wheel', {
            autoAlpha: 0,
            y: -300,
            duration: 0.1,
            immediateRender: false,
            ease: "power1.inOut",
        }, '<');





    }


    var timeLineAfter2;

    /**
    * INIZIO TIMELINE CHI SIAMO
    * Timeline da chiamare dopo i pianeti
    * In questa sezione lo spazio e tutti i contenuti fanno un translate 
    * verso sopra facendo diventare lo spazio bianco
    */
    function animationAfterPlanets() {
        /**
     * INIZIO TIMELINE CHI SIAMO
     * Timeline da chiamare dopo i pianeti
     * In questa sezione lo spazio e tutti i contenuti fanno un translate 
     * verso sopra facendo diventare lo spazio bianco
     */
        let timeLineAfter = null;
        if (window.innerWidth >= 1024) {
            timeLineAfter = new gsap.TimelineMax({
                scrollTrigger: {
                    trigger: '#last-section-content',
                    start: 'top 70%',
                    scrub: 1,
                    onUpdate: self => {

                    },
                },
            })
            timeLineAfter.to('.home__background', {
                y: '-100%',
            }, '<');
            timeLineAfter.to('.logo', {
                autoAlpha: '0',
                ease: "power1.inOut",
            }, '<');
            timeLineAfter.to('#section02', {
                autoAlpha: 0,
                duration: 0.1,
            }, '<');


        }






        /**
         * INIZIO TIMELINE CHI SIAMO
         * Timeline da chiamare dopo i pianeti
         * In questa sezione lo spazio e tutti i contenuti fanno un translate 
         * verso sopra facendo diventare lo spazio bianco
         */
        if (window.innerWidth >= 1024) {
            timeLineAfter2 = new gsap.TimelineMax({
                scrollTrigger: {
                    trigger: '#last-section-content',
                    start: 'top top',
                    end: '+=1800',
                    pin: true,
                    scrub: 1,
                    onUpdate: self => {

                    },
                },
            })


        }
        else {
            timeLineAfter2 = new gsap.TimelineMax({
                scrollTrigger: {
                    trigger: '#last-section-content',
                    start: 'top top',
                    end: '+=800',

                    pin: true,
                    scrub: 1,
                    onUpdate: self => {

                    },
                },
            })
        }


        timeLineAfter2.from('#ls-title', {
            y: 400,
        }, '<');
        timeLineAfter2.from('#ls-sub', {
            y: 450,
        }, '<0');
        timeLineAfter2.to('.liftoff-button', {
            autoAlpha: 0,
        }, '<0');

        timeLineAfter2.to('#goback', {
            autoAlpha: 1,
            y: 0,
        }, '<');
        timeLineAfter2.from('#ls-sub2', {
            y: 500,
        }, '<0');
        timeLineAfter2.from('#ls-buttons', {
            y: 550,
        }, '<').addLabel("chisiamo");
        ;

        timeLineAfter2.from('.at-footer', {
            scale: 0.9,
            ease: "power1.inOut",
        }, '<0.6');





    }



    /**
     * Metodo principale che controlla tutte le animazioni
     * Bisogna fare il detect del resize
     * Al rezize richiamo tutta la funzione che fa tutti i calcol
     * TODO Listener resize
     * @param {*} planets  I pianeti che passano durante l'animazione
     */
    function animatePlanetsOnScroll(planets) {

        if (timeLinePlanets && timeLinePlanets.scrollTrigger) {
            timeLinePlanets.scrollTrigger.kill();
        }
        console.log('PLANET CONTENT ')

        MotionPathPlugin.convertToPath('#circlePath');



        let endTrigger = 2000 * planets.length;

        let endTriggerMobile = 1200 * planets.length;


        let interval = 100 / planets.length * 0.01;




        /**
         * Timeline dei pianeti
         * INIZIONE TIMELINE PIANETI
         */

        if (window.innerWidth > 1023) {
            timeLinePlanets = new gsap.TimelineMax({
                scrollTrigger: {
                    trigger: '#section02',
                    start: 'top top',
                    end: '+=' + endTrigger,
                    pin: true,
                    scrub: 1,
                    snap: {
                        snapTo: "labels", // snap to the closest label in the timeline
                        duration: { min: 0.2, max: 1 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
                        delay: 0, // wait 0.2 seconds from the last scroll event before doing the snapping
                        //  ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
                    },
                    onUpdate: self => {

                        if (self.progress >= 0) {
                            setNavigator(1);
                            let url = planets[0].pageUrl;
                            setUrlCs(url);

                        }
                        if (self.progress >= interval) {
                            setNavigator(2);
                            let url = planets[1].pageUrl;
                            setUrlCs(url);
                        }
                        if (self.progress >= interval * 2) {
                            setNavigator(3);
                            let url = planets[2].pageUrl;
                            setUrlCs(url);
                        }
                        if (self.progress >= interval * 3) {
                            setNavigator(4);
                            let url = planets[3].pageUrl;
                            setUrlCs(url);
                        }
                        if (self.progress >= interval * 4) {
                            setNavigator(5);
                            let url = planets[4].pageUrl;
                            setUrlCs(url);
                        }
                        if (self.progress >= interval * 5) {
                            setNavigator(6);
                            let url = planets[5].pageUrl;
                            setUrlCs(url);
                        }
                        if (self.progress >= interval * 6) {
                            setNavigator(7);
                            if (planets[6]) {
                                let url = planets[6].pageUrl;
                                setUrlCs(url);
                            }

                        }
                        if (self.progress >= interval * 7) {
                            setNavigator(8);
                            let url = planets[7].pageUrl;
                            setUrlCs(url);
                        }
                        if (self.progress >= interval * 8) {
                            setNavigator(9);
                            let url = planets[8].pageUrl;
                            setUrlCs(url);
                        }


                    },
                },
                defaults: { duration: 1, ease: 'power1.inOut' }
            })
            timeLinePlanets.from('#greenCircles', {
                scale: 0.8,
                x: '500',
                autoAlpha: 0.3,
                duration: 0.7
            }, '<');
            timeLinePlanets.to('#chisiamo', {
                autoAlpha: 1,
                y: 0,
                ease: "power1.inOut",
            }, '<')

            timeLinePlanets.from('.navigator', {
                autoAlpha: 0,
                ease: "power1.inOut",
                duration: 0.6
            }, '<')

            timeLinePlanets.to('#fp-nav', {
                autoAlpha: 1,
                x: 0,
                ease: "power1.inOut",
                duration: 0.5
            }, '<')
            timeLinePlanets.to('.liftoff-button', {
                autoAlpha: 1,
                ease: "power1.inOut",
                duration: 0.3
            }, '>0.3')


            let i = 0;
            planets.forEach(planet => {
                let planet1 = timeLinePlanets.to('#planet-' + i, {
                    //    autoAlpha: 1,

                    motionPath: {
                        path: "#circlePath",
                        align: "#circlePath",
                        alignOrigin: [0.5, 0.5],
                        start: 0.90,
                        end: 0.5,

                    },
                })
                    .from('#title-' + i, {
                        y: 300,
                        autoAlpha: 0,

                        ease: "power1.inOut",

                    }, '<0')
                    .from('#subtitle-' + i, {

                        y: 300,
                        autoAlpha: 0,

                        ease: "power1.inOut",

                    }, "<0.2")

                    .to('#planet-' + i, {
                        motionPath: {
                            path: "#circlePath",
                            align: "#circlePath",
                            alignOrigin: [0.5, 0.5],
                            start: 0.5,
                            end: 0.10
                        },
                    }, '<1').addLabel("labelPlanet-" + i, "-=1")
                    .to('#title-' + i, {

                        y: -400,
                        autoAlpha: 0,

                        ease: "power1.inOut",

                    }, '<0')
                    .to('#subtitle-' + i, {

                        y: -400,
                        autoAlpha: 0,

                        ease: "power1.inOut",

                    }, "<0.1") // start 1 sec after last tween
                i++;
            });

            timeLinePlanets.to('.at-page-2020', {
                background: 'white',
                ease: "power1.inOut",
                duration: 0,
            }, '<');


        }







    }




    useEffect(() => {

        if (window.innerWidth >= 1024) {


            setTimeout(() => {
                animateFirstSection();

            }, 1200);
            setTimeout(() => {
                setFp(true);
            }, 3200);
            setTimeout(() => {
                animatePlanetsOnScroll(planets);

            }, 3000);
            setTimeout(() => {
                animationAfterPlanets();

            }, 4000);
            //Quando il componente viene smontato
            return function cleanup() {

                if (timeLinePlanets && timeLinePlanets.scrollTrigger) {
                    timeLinePlanets.scrollTrigger.kill();

                }
                if (timeLineAfter2 && timeLineAfter2.scrollTrigger) {
                    timeLineAfter2.scrollTrigger.kill();

                }

            }
        }


    }, []);


    /**
   * Questo metodo viene utilizzato per navigare all'interno dei casi studio
   * Richiamo la timeline dei pianeti e vado appositamente alla posizione designata
   * @param {*} navigator Navigatore di riferimento
   */
    function handleClickOnNavigator(navigator) {

        //Vecchio indicatore
        let oldNavigator = navigatorSelected;
        //Nuovo indicatore
        setNavigator(navigator);

        //Definisco la variabile di tempo per la transizione.
        let duration = 1;


        let indicatorPlanet = navigator - 1;



        if (oldNavigator > indicatorPlanet) {
            let difference = oldNavigator - indicatorPlanet;
            switch (difference) {
                case 1:
                    duration = 1.5;
                    break;
                case 2:
                    duration = 3;
                    break;
                case 3:
                    duration = 4;
                    break;
                case 4:
                    duration = 5;
                    break;
                case 5:
                    duration = 6;
                    break;
                case 6:
                    duration = 7;
                    break;

                case 7:
                    duration = 8;
                    break;
                case 8:
                    duration = 9;
                    break;
                case 9:
                    duration = 10;
                    break;
                case 10:
                    duration = 11;
                    break;
                case 11:
                    duration = 3;
                    break;
                default:

                // code block
            }



        } else {
            let difference = indicatorPlanet - oldNavigator;
            switch (difference) {
                case 1:
                    duration = 1.5;
                    break;
                case 2:
                    duration = 3;
                    break;
                case 3:
                    duration = 4;
                    break;
                case 4:
                    duration = 5;
                    break;
                case 5:
                    duration = 6;
                    break;
                case 6:
                    duration = 7;
                    break;

                case 7:
                    duration = 8;
                    break;
                case 8:
                    duration = 9;
                    break;
                case 9:
                    duration = 10;
                    break;
                case 10:
                    duration = 11;
                    break;
                case 11:
                    duration = 3;
                    break;
                default:

                // code block
            }



        }


        let labelName = "labelPlanet-" + indicatorPlanet;
        scrollToParameter(labelName, duration);

        // timeLinePlanets.timeScale(2.4).tweenFromTo(timeLinePlanets.time(),"labelPlanet-" + indicatorPlanet, {onComplete:scrollToParameter});

    }

    /**
     * Torno indietro nello scorrimento dei pianenti
     */
    function goBackToPlanet() {
        if (navigatorSelected == 1) {
            //Do nothing
        } else {
            let oldNavigator = navigatorSelected;

            handleClickOnNavigator(oldNavigator - 1)

        }
    }

    /**
     * Vai avanti nello scorrimento dei pianeti
     */
    function goForwardToPlanet() {
        if (navigatorSelected == planets.length) {
            //Do nothing
        } else {
            let oldNavigator = navigatorSelected;
            handleClickOnNavigator(oldNavigator + 1)

        }
    }
    /**
     * Effettua uno scroll alla relativa label di riferimento
     * @param {string} labelName nome della label di riferimento
     * @param {number} duration durata dell'animazione (in secondi)
     * https://greensock.com/scrolltoplugin/
     */
    function scrollToParameter(labelName, duration) {
        //Posizione della label
        let timeLineLabel = timeLinePlanets.labels[labelName];
        let timeLineDuration = timeLinePlanets.duration();
        let scrollTriggerEnd = timeLinePlanets.scrollTrigger.end;
        let scrollTriggerStart = timeLinePlanets.scrollTrigger.start;
        let labelPosition = ((timeLineLabel / timeLineDuration) * (scrollTriggerEnd - scrollTriggerStart)) + scrollTriggerStart;
        TweenMax.to(window, { duration: duration, scrollTo: labelPosition, ease: 'none' })
    }

    function handleOnBackToSpace() {
        TweenMax.to(window, { duration: 0.1, scrollTo: 0, ease: 'none' });

    }

    const mouseOverText = () => {
        setUnderLineWidth('100%');
    }

    const mouseOutText = () => {
        setUnderLineWidth('0%');
    }

    return (
        <>

            <div id="goback" className="at-nav-button-wrapper-back">

                <button className="at-nav-button" onClick={handleOnBackToSpace}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.943"
                        height="10.43"
                        viewBox="0 0 16.943 10.43"
                        className="mr-3"
                    >
                        <defs>
                            <clipPath id="clip-path">
                                <path
                                    id="Tracciato_686"
                                    data-name="Tracciato 686"
                                    d="M1.53-5.35H14.72L12.37-2.41a1,1,0,0,0-.213.738A1,1,0,0,0,12.53-1a1,1,0,0,0,1.4-.16l3.28-4.09a1.2,1.2,0,0,0,0-1.5l-3.28-4.09a1,1,0,0,0-1.4-.16,1,1,0,0,0-.373.672,1,1,0,0,0,.213.738l1.8,2.24H1.53a1,1,0,0,0-1,1A1,1,0,0,0,1.53-5.35Z"
                                    fill="#a7a7a7"
                                />
                            </clipPath>
                            <clipPath id="clip-path-2">
                                <path id="Tracciato_685" data-name="Tracciato 685" d="M-248,302H634V-1528H-248Z" fill="#a7a7a7" />
                            </clipPath>
                        </defs>
                        <g id="Raggruppa_519" data-name="Raggruppa 519" transform="translate(-0.53 11.215)" clipPath="url(#clip-path)">
                            <g id="Raggruppa_518" data-name="Raggruppa 518" clipPath="url(#clip-path-2)">
                                <path id="Tracciato_684" data-name="Tracciato 684" d="M-4.47-16.215H22.473V4.215H-4.47Z" fill="#a7a7a7" />
                            </g>
                        </g>
                    </svg>
                    TORNA NELLO SPAZIO
                </button>
            </div>
            <div id="chisiamo" className="at-nav-button-wrapper-chisiamo">
                <button className="at-nav-button" onClick={props.handleOnChiSiamo}>

                    SU DI NOI
                </button>
                <img className="img-bottom" src={arrowBottom} ></img>

            </div>
            <Link to={'/case-study/' + urlCs}>
                <LiftoffButton style={{
                    position: 'fixed',

                }} />
            </Link>


            <section id="section02">
                <div className="planets">


                    {planets.map((p, i) => {
                        return (
                            <Planet
                                {...p}
                                id={"planet-" + i}
                                key={"planet-" + i}
                                style={{
                                    // top: points[1].y,
                                    // left: points[1].x,
                                    // transform: 
                                    // 	i === 0 ?
                                    // 	'translate(calc(' + radius + 'px - 50%), calc(' + radius*2 + 'px - 50%))'
                                    // 	:
                                    // 	'translate(calc(' + radius*2 + 'px - 50%), calc(' + radius + 'px - 50%))'
                                }}
                            />
                        );
                    })}

                </div>

                <svg id="greenCircles" viewBox="0 0 300 200">

                    <circle id="bigGreen" cx="300" cy="100" r="100" stroke="none" fill="none" />

                    <circle id="small" cx="400" cy="100" r="30" stroke="none" fill="none" />

                    <circle stroke="white" strokeWidth="0.5" id="circlePath" fill="none" cx="300" cy="100" r="100" />
                </svg>
                <div id="galaxy" className="orbit">


                    <div className="text__planets">
                        <div className="container">

                            <div className="col-xs-8 col-sm-8 col-md-6">
                                <div className="navigator">

                                    <img onClick={() => goBackToPlanet()} className="navigatorLeft" src={leftArrow}></img>

                                    <img onClick={() => goForwardToPlanet()} className="navigatorRight" src={leftArrow}></img>

                                </div>

                                {planets.map((p, i) => {
                                    return (
                                        <div key={"text-" + i} onClick={() => navigate('/case-study/' + urlCs)} onMouseOver={() => mouseOverText()} onMouseOut={() => mouseOutText()}
                                            className="text__planets__content">
                                            <h3 className={p.class} id={"title-" + i}
                                            >{p.subtitle}</h3>
                                            <p id={"subtitle-" + i}
                                            >{p.title}<div className="subtitle-underline-orange" style={{ width: underLineWidth }}>
                                                </div></p>
                                        </div>
                                    );
                                })}



                            </div>

                        </div>
                    </div>





                </div>

                <div id="fp-nav" className={showFp ? 'fp-right' : 'fp-right visibility-none'}>
                    <ul>
                        <li data-tip={planets[0].title} onClick={() => handleClickOnNavigator(1)}><a className={navigatorSelected === 1 ? "active" : ''}><span className="fp-sr-only">Section 1</span><span></span></a></li>
                        <li data-tip={planets[1].title} onClick={() => handleClickOnNavigator(2)}><a className={navigatorSelected === 2 ? "active" : ''}><span className="fp-sr-only">Section 1</span><span></span></a></li>
                        <li data-tip={planets[2].title} onClick={() => handleClickOnNavigator(3)}><a className={navigatorSelected === 3 ? "active" : ''}><span className="fp-sr-only">Section 1</span><span></span></a></li>
                        <li data-tip={planets[3].title} onClick={() => handleClickOnNavigator(4)}><a className={navigatorSelected === 4 ? "active" : ''}><span className="fp-sr-only">Section 1</span><span></span></a></li>
                        <li data-tip={planets[4].title} onClick={() => handleClickOnNavigator(5)}><a className={navigatorSelected === 5 ? "active" : ''}><span className="fp-sr-only">Section 1</span><span></span></a></li>
                        <li data-tip={planets[5].title} onClick={() => handleClickOnNavigator(6)}><a className={navigatorSelected === 6 ? "active" : ''}><span className="fp-sr-only">Section 1</span><span></span></a></li>
                        <li data-tip={planets[6].title} onClick={() => handleClickOnNavigator(7)}><a className={navigatorSelected === 7 ? "active" : ''}><span className="fp-sr-only">Section 1</span><span></span></a></li>
                        <li data-tip={planets[7].title} onClick={() => handleClickOnNavigator(8)}><a className={navigatorSelected === 8 ? "active" : ''}><span className="fp-sr-only">Section 1</span><span></span></a></li>

                        <li data-tip={planets[8].title} onClick={() => handleClickOnNavigator(9)}><a className={navigatorSelected === 9 ? "active" : ''}><span className="fp-sr-only">Section 1</span><span></span></a></li>



                    </ul>

                </div>



            </section>


            <ReactTooltip />
        </>
    );
};




export default PlanetsContent;
