/* JS */

/* react */
import React from 'react';
/* third part libraries */

/* custom components */
import Button from './Button/Button';
/* STYLE */

/* third part styles */

/* custom styles */
import './WelcomePage.css';

const mouseWheel = require('../images/wheel.png');
const swipeRight = require('../images/swipe-right.svg');

class WelcomePage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			width: 0,
		};

		this.handleOnWindowSizeChange = this.handleOnWindowSizeChange.bind(this);
	}

	componentDidMount() {
		this.handleOnWindowSizeChange();
		window.addEventListener('resize', this.handleOnWindowSizeChange);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleOnWindowSizeChange);
	}

	handleOnWindowSizeChange() {
		this.setState({ width: window.innerWidth });
	}

	render() {
		const isMobile = this.state.width <= 812;

		return (
			<div className={'welcome-page ' + (this.props.className || '')}>
				<h2 className="claim">
					Behind <br />
					your success<span className="orange">.</span>
				</h2>
				{!isMobile ? (
					<Button className="mouse-wheel">
						<img src={mouseWheel} alt="Mouse wheel" />
					</Button>
				) : (
					<div className="swipe-right">
						<img src={swipeRight} alt="Swipe Right" />
					</div>
				)}
			</div>
		);
	}
}

export default WelcomePage;
