import React, { useEffect, useState } from 'react';

import {
    ScrollTrigger,
    Draggable,
    MotionPathPlugin,
    ScrollToPlugin,
    TweenMax
} from "gsap/all";

import Planet from '../../Planets/Planet2';

import './PlanetsContentMobile.scss';

import { Link } from 'gatsby';
import LiftoffButton from '../../Button/LiftoffButton';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Controller, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { object } from 'prop-types';

const leftArrow = require('../../../images/planets/new/nav_sx.svg');
const arrowBottom = require('../../../images/planets/new/frecciagiu.svg');


var gsap;
var detect = require('is-client');
if (detect()) {
    gsap = require('gsap');
    gsap.gsap.registerPlugin(ScrollTrigger, Draggable, MotionPathPlugin, ScrollToPlugin);
}

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Controller]);


var timeLinePlanets;

function splitCircumference(cx, cy, r, n) {
    let coordinates = [];

    for (let i = 1; i <= n; i++) {
        let radians = ((2 * Math.PI) / n) * i;
        let endX = cx + r * Math.cos(radians);
        let endY = cy - r * Math.sin(radians);
        coordinates.push({
            x: Math.round(endX),
            y: Math.round(endY),
        });
    }

    return coordinates;
}



var swiperElementObj;



const PlanetsContentMobile = props => {
    const [center, setCenter] = useState({ x: 0, y: 0 });
    const [radius, setRadius] = useState(100);
    const [navigatorSelected, setNavigator] = useState(1);
    const [showFp, setFp] = useState(false);
    const [swiper, setSwiper] = useState(null);
    let planets = props.planets;

    const [urlCs, setUrlCs] = useState('idm');




    /***
 * Animazione Apertura
 */



    var timeLineAfter2;

    /**
    * INIZIO TIMELINE CHI SIAMO
    * Timeline da chiamare dopo i pianeti
    * In questa sezione lo spazio e tutti i contenuti fanno un translate 
    * verso sopra facendo diventare lo spazio bianco
    */




    /**
     * Metodo principale che controlla tutte le animazioni
     * Bisogna fare il detect del resize
     * Al rezize richiamo tutta la funzione che fa tutti i calcol
     * TODO Listener resize
     * @param {*} planets  I pianeti che passano durante l'animazione
     */
    function animatePlanetsOnScroll(planets) {

        if (timeLinePlanets && timeLinePlanets.scrollTrigger) {
            timeLinePlanets.scrollTrigger.kill();
        }







        gsap.gsap.to('.liftoff-button', {

            scrollTrigger: {
                trigger: '#section02',
                start: 'top top',
                end: '+=1000',
                pin: true,
                scrub: 1,
                onUpdate: self => {
                    console.log(self.progress);
                },

            }
        });


        gsap.gsap.to('.liftoff-button', {
            autoAlpha: 1,
            y: 0,
            scrollTrigger: {
                trigger: '#section02',
                start: 'top 40%',
                end: 'top 50%',
                scrub: 1,
                onUpdate: self => {
                    console.log(self.progress);
                },

            }
        });


        gsap.gsap.to('.at-page-2020', {
            background: 'white',

            immediateRender: false,

            scrollTrigger: {
                trigger: '#section02',
                start: 'top 40%',
                end: 'top 50%',
                scrub: 1,

            }
        });



        gsap.gsap.to('.liftoff-button', {
            auutoAlpha: 0,
            y: 300,
            immediateRender: false,

            scrollTrigger: {
                trigger: '#last-section-content',
                start: 'top bottom',
                end: '+=200',
                scrub: 1,
                onUpdate: self => {
                },
            }
        });





        gsap.gsap.to('.home__background', {
            y: '-100%',
            immediateRender: false,

            scrollTrigger: {
                trigger: '#last-section-content',
                start: 'top 85%',
                end: '+=600',
                scrub: 1,
                onUpdate: self => {
                },
            }
        });

        gsap.gsap.to('.planetsMobile', {
            y: '-50%',

            scrollTrigger: {
                trigger: '#last-section-content',
                start: 'top 85%',
                end: '+=600',
                scrub: 1,
                onUpdate: self => {
                },
            }
        });




        gsap.gsap.from('#ls-title', {
            y: 200,

            scrollTrigger: {
                trigger: '#last-section-content',
                start: '50% bottom',
                end: '+=750',
                scrub: 1,
                onUpdate: self => {
                },
            }
        });

        gsap.gsap.from('#ls-sub', {
            y: 240,

            scrollTrigger: {
                trigger: '#last-section-content',
                start: '50% bottom',
                end: '+=750',
                scrub: 1,
                onUpdate: self => {
                },
            }
        });

        gsap.gsap.from('#ls-sub2', {
            y: 270,

            scrollTrigger: {
                trigger: '#last-section-content',
                start: '50% bottom',
                end: '+=750',
                scrub: 1,
                onUpdate: self => {
                },
            }
        });

        gsap.gsap.from('#ls-buttons', {
            y: 290,

            scrollTrigger: {
                trigger: '#last-section-content',
                start: '50% bottom',
                end: '+=750',
                scrub: 1,
                onUpdate: self => {
                },
            }
        });












    }




    useEffect(() => {

        setTimeout(() => {
            //     animateFirstSection();
        }, 1200);
        setTimeout(() => {
            setFp(true);
        }, 3200);
        setTimeout(() => {
            animatePlanetsOnScroll(planets);

        }, 3000);
        setTimeout(() => {
            //         animationAfterPlanets();

        }, 4000);
        //Quando il componente viene smontato
        return function cleanup() {

        }

    }, []);


    /**
   * Questo metodo viene utilizzato per navigare all'interno dei casi studio
   * Richiamo la timeline dei pianeti e vado appositamente alla posizione designata
   * @param {*} navigator Navigatore di riferimento
   */
    function handleClickOnNavigator(navigator) {

        //Vecchio indicatore
        let oldNavigator = navigatorSelected;
        //Nuovo indicatore
        setNavigator(navigator);

        //Definisco la variabile di tempo per la transizione.
        let duration = 1;


        let indicatorPlanet = navigator - 1;



        if (oldNavigator > indicatorPlanet) {
            let difference = oldNavigator - indicatorPlanet;
            switch (difference) {
                case 1:
                    duration = 1.5;
                    break;
                case 2:
                    duration = 3;
                    break;
                case 3:
                    duration = 4;
                    break;
                case 4:
                    duration = 5;
                    break;
                case 5:
                    duration = 6;
                    break;
                case 6:
                    duration = 7;
                    break;

                case 7:
                    duration = 8;
                    break;
                case 8:
                    duration = 9;
                    break;
                case 9:
                    duration = 10;
                    break;
                case 10:
                    duration = 11;
                    break;
                case 11:
                    duration = 3;
                    break;
                default:

                // code block
            }



        } else {
            let difference = indicatorPlanet - oldNavigator;
            switch (difference) {
                case 1:
                    duration = 1.5;
                    break;
                case 2:
                    duration = 3;
                    break;
                case 3:
                    duration = 4;
                    break;
                case 4:
                    duration = 5;
                    break;
                case 5:
                    duration = 6;
                    break;
                case 6:
                    duration = 7;
                    break;

                case 7:
                    duration = 8;
                    break;
                case 8:
                    duration = 9;
                    break;
                case 9:
                    duration = 10;
                    break;
                case 10:
                    duration = 11;
                    break;
                case 11:
                    duration = 3;
                    break;
                default:

                // code block
            }



        }


        let labelName = "labelPlanet-" + indicatorPlanet;
        scrollToParameter(labelName, duration);

        // timeLinePlanets.timeScale(2.4).tweenFromTo(timeLinePlanets.time(),"labelPlanet-" + indicatorPlanet, {onComplete:scrollToParameter});

    }

    /**
     * Torno indietro nello scorrimento dei pianenti
     */
    function goBackToPlanet() {
        if (navigatorSelected == 1) {
            //Do nothing
        } else {
            let oldNavigator = navigatorSelected;

            handleClickOnNavigator(oldNavigator - 1)

        }
    }


    /**
     * Vai avanti nello scorrimento dei pianeti
     */

    /**
     * Effettua uno scroll alla relativa label di riferimento
     * @param {string} labelName nome della label di riferimento
     * @param {number} duration durata dell'animazione (in secondi)
     * https://greensock.com/scrolltoplugin/
     */
    function scrollToParameter(labelName, duration) {
        //Posizione della label
        let timeLineLabel = timeLinePlanets.labels[labelName];
        let timeLineDuration = timeLinePlanets.duration();
        let scrollTriggerEnd = timeLinePlanets.scrollTrigger.end;
        let scrollTriggerStart = timeLinePlanets.scrollTrigger.start;
        let labelPosition = ((timeLineLabel / timeLineDuration) * (scrollTriggerEnd - scrollTriggerStart)) + scrollTriggerStart;
        TweenMax.to(window, { duration: duration, scrollTo: labelPosition, ease: 'none' })
    }

    function handleOnBackToSpace() {
        TweenMax.to(window, { duration: 0.1, scrollTo: 0, ease: 'none' });

    }




    /**
  * Funzione per gestire il cambio di slide
  */
    function handleSwiperChange(swiperElement) {
        swiperElementObj = swiperElement;
        console.log(swiperElementObj)
        if (swiperElementObj.activeIndex == 0) {
            setNavigator(1);
            let url = planets[0].pageUrl;
            setUrlCs(url);

        }
        if (swiperElementObj.activeIndex == 1) {
            setNavigator(2);
            let url = planets[1].pageUrl;
            setUrlCs(url);
        }
        if (swiperElementObj.activeIndex == 2) {
            setNavigator(3);
            let url = planets[2].pageUrl;
            setUrlCs(url);
        }
        if (swiperElementObj.activeIndex == 3) {
            setNavigator(4);
            let url = planets[3].pageUrl;
            setUrlCs(url);
        }
        if (swiperElementObj.activeIndex == 4) {
            setNavigator(5);
            let url = planets[4].pageUrl;
            setUrlCs(url);
        }
        if (swiperElementObj.activeIndex == 5) {
            setNavigator(6);
            let url = planets[5].pageUrl;
            setUrlCs(url);
        }
        if (swiperElementObj.activeIndex == 6) {
            setNavigator(7);
            if (planets[6]) {
                let url = planets[6].pageUrl;
                setUrlCs(url);
            }

        }
        if (swiperElementObj.activeIndex == 7) {
            setNavigator(8);
            let url = planets[7].pageUrl;
            setUrlCs(url);
        }
        if (swiperElementObj.activeIndex == 8) {
            setNavigator(9);
            let url = planets[8].pageUrl;
            setUrlCs(url);
        }
        if (swiperElementObj.activeIndex == 9) {
            setNavigator(10);
            let url = planets[9].pageUrl;
            setUrlCs(url);
        }
        if (swiperElementObj.activeIndex == 10) {
            setNavigator(11);
            if (planets[10]) {

                let url = planets[10].pageUrl;
                setUrlCs(url);
            }
        }
    }

    function goForwardToPlanet() {
        console.log(swiperElementObj.activeIndex);
        swiperElementObj.slideNext();
    }

    function goBackToPlanet() {
        console.log(swiperElementObj.activeIndex);
        swiperElementObj.slidePrev();

    }
    return (
        <>

            <div id="goback" className="at-nav-button-wrapper-back">

                <button className="at-nav-button" onClick={handleOnBackToSpace}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.943"
                        height="10.43"
                        viewBox="0 0 16.943 10.43"
                        className="mr-3"
                    >
                        <defs>
                            <clipPath id="clip-path">
                                <path
                                    id="Tracciato_686"
                                    data-name="Tracciato 686"
                                    d="M1.53-5.35H14.72L12.37-2.41a1,1,0,0,0-.213.738A1,1,0,0,0,12.53-1a1,1,0,0,0,1.4-.16l3.28-4.09a1.2,1.2,0,0,0,0-1.5l-3.28-4.09a1,1,0,0,0-1.4-.16,1,1,0,0,0-.373.672,1,1,0,0,0,.213.738l1.8,2.24H1.53a1,1,0,0,0-1,1A1,1,0,0,0,1.53-5.35Z"
                                    fill="#a7a7a7"
                                />
                            </clipPath>
                            <clipPath id="clip-path-2">
                                <path id="Tracciato_685" data-name="Tracciato 685" d="M-248,302H634V-1528H-248Z" fill="#a7a7a7" />
                            </clipPath>
                        </defs>
                        <g id="Raggruppa_519" data-name="Raggruppa 519" transform="translate(-0.53 11.215)" clipPath="url(#clip-path)">
                            <g id="Raggruppa_518" data-name="Raggruppa 518" clipPath="url(#clip-path-2)">
                                <path id="Tracciato_684" data-name="Tracciato 684" d="M-4.47-16.215H22.473V4.215H-4.47Z" fill="#a7a7a7" />
                            </g>
                        </g>
                    </svg>
                    TORNA NELLO SPAZIO
                </button>
            </div>
            <div id="chisiamo" className="at-nav-button-wrapper-chisiamo">
                <button className="at-nav-button" onClick={props.handleOnChiSiamo}>

                    SU DI NOI
                </button>
                <img className="img-bottom" src={arrowBottom} ></img>

            </div>
            <Link to={'/case-study/' + urlCs}>
                <LiftoffButton style={{
                    position: 'fixed',

                }} />
            </Link>


            <section id="section02">
                <div className="planetsMobile">
                    <div className="navigator">

                        <img onClick={() => goBackToPlanet()} className="navigatorLeft" src={leftArrow}></img>

                        <img onClick={() => goForwardToPlanet()} className="navigatorRight" src={leftArrow}></img>

                    </div>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={2}
                        navigation
                        centeredSlides



                        //    onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => handleSwiperChange(swiperElementObj)}
                        onSwiper={(swiper) => swiperElementObj = swiper}
                    //   controller={{ control: firstSwiper }    
                    >


                        {planets.map((p, i) => {
                            return (
                                <SwiperSlide>
                                    <div key={"text-" + i}
                                        className="text__planets__content--mobile">
                                        <h3 className={p.class} id={"title-" + i}
                                        >{p.subtitle}</h3>
                                        <p id={"subtitle-" + i}
                                        >{p.title}</p>
                                    </div>
                                    <Planet
                                        {...p}
                                        id={"planet-" + i}
                                        key={"planet-" + i}
                                        style={{
                                            // top: points[1].y,
                                            // left: points[1].x,
                                            // transform: 
                                            // 	i === 0 ?
                                            // 	'translate(calc(' + radius + 'px - 50%), calc(' + radius*2 + 'px - 50%))'
                                            // 	:
                                            // 	'translate(calc(' + radius*2 + 'px - 50%), calc(' + radius + 'px - 50%))'
                                        }}
                                    />
                                </SwiperSlide>
                            );
                        })}


                    </Swiper>


                    <div id="fp-nav" className={showFp ? 'fp-right' : 'fp-right visibility-none'}>
                        <ul>
                            <li onClick={() => handleClickOnNavigator(1)}><a className={navigatorSelected === 1 ? "active" : ''}><span className="fp-sr-only">Section 1</span><span></span></a></li>
                            <li onClick={() => handleClickOnNavigator(2)}><a className={navigatorSelected === 2 ? "active" : ''}><span className="fp-sr-only">Section 1</span><span></span></a></li>
                            <li onClick={() => handleClickOnNavigator(3)}><a className={navigatorSelected === 3 ? "active" : ''}><span className="fp-sr-only">Section 1</span><span></span></a></li>
                            <li onClick={() => handleClickOnNavigator(4)}><a className={navigatorSelected === 4 ? "active" : ''}><span className="fp-sr-only">Section 1</span><span></span></a></li>
                            <li onClick={() => handleClickOnNavigator(5)}><a className={navigatorSelected === 5 ? "active" : ''}><span className="fp-sr-only">Section 1</span><span></span></a></li>
                            <li onClick={() => handleClickOnNavigator(6)}><a className={navigatorSelected === 6 ? "active" : ''}><span className="fp-sr-only">Section 1</span><span></span></a></li>
                            <li onClick={() => handleClickOnNavigator(7)}><a className={navigatorSelected === 7 ? "active" : ''}><span className="fp-sr-only">Section 1</span><span></span></a></li>
                            <li onClick={() => handleClickOnNavigator(8)}><a className={navigatorSelected === 8 ? "active" : ''}><span className="fp-sr-only">Section 1</span><span></span></a></li>

                            <li onClick={() => handleClickOnNavigator(9)}><a className={navigatorSelected === 9 ? "active" : ''}><span className="fp-sr-only">Section 1</span><span></span></a></li>



                        </ul>


                    </div>

                </div>





            </section>



        </>
    );
};




export default PlanetsContentMobile;
