import React, { useEffect, useState } from 'react';
import ScrollMagic from 'scrollmagic-with-ssr';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';

import Planet from './Planet2';

import './PlanetsOrbit.css';
import './PlanetsOrbit2.scss';

var gsap;
var detect = require('is-client');
if (detect()) {
	gsap = require('gsap');
	let mpp = require('gsap/MotionPathPlugin');
	gsap.gsap.registerPlugin(mpp);
}

if (ScrollMagic && ScrollMagicPluginGsap && gsap) ScrollMagicPluginGsap(ScrollMagic, gsap);

function splitCircumference(cx, cy, r, n) {
	let coordinates = [];

	for (let i = 1; i <= n; i++) {
		let radians = ((2 * Math.PI) / n) * i;
		let endX = cx + r * Math.cos(radians);
		let endY = cy - r * Math.sin(radians);
		coordinates.push({
			x: Math.round(endX),
			y: Math.round(endY),
		});
	}

	return coordinates;
}

let controller;


function createTimelineSection0() {
	if (ScrollMagic && gsap) {

		let tlSection0 = new gsap.TimelineMax();
		let tween0 = gsap.TweenMax.to('#planet-0', 1, {
			motionPath: {
				path: "#path4",
				autoRotate: false,
				alignOrigin: [0.5, 0.5]
			},
			duration: '100%'
		});
		let tween1 = gsap.TweenMax.to('#planet-1', 1, {
			motionPath: {
				path: "#path3",
				autoRotate: false,
				alignOrigin: [0.5, 0.5]
			},
			duration: '100%'
		});
		let tween2 = gsap.TweenMax.to('#planet-2', 1, {
			motionPath: {
				path: "#path3",
				autoRotate: false,
				alignOrigin: [0.5, 0.5]
			},
			duration: '100%'
		});
		let tween3 = gsap.TweenMax.to('#planet-3', 1, {
			motionPath: {
				path: "#path3",
				autoRotate: false,
				alignOrigin: [0.5, 0.5]
			},
			duration: '100%'
		});

		tlSection0.add(tween0).add(tween1).add(tween2).add(tween3);

		new ScrollMagic.Scene({
			triggerElement: '#section-0',
			duration: '100%',
			triggerHook: 'onEnter',
			tweenChanges: false
		})
		.setTween(tlSection0)
		.addTo(controller);
	}
}

function createTimelineSection1() {
	if (ScrollMagic && gsap) {

		let tlSection1 = new gsap.TimelineMax();
		let tween0 = gsap.TweenMax.to('#planet-0', 1, {
			motionPath: {
				path: "#path1",
				autoRotate: false,
				alignOrigin: [0.5, 0]
			},
			duration: '100%'
		});
		let tween1 = gsap.TweenMax.to('#planet-1', 1, {
			motionPath: {
				path: "#path4",
				autoRotate: false,
				alignOrigin: [0.5, 0]
			},
			duration: '100%'
		});

		tlSection1.add(tween0).add(tween1);

		new ScrollMagic.Scene({
			triggerElement: '#section-1',
			duration: '100%',
			triggerHook: 'onEnter',
			tweenChanges: false
		})
		.setTween(tlSection1)
		.addTo(controller);
	}
}

function createTimelineSection2() {
	if (ScrollMagic && gsap) {

		let tlSection2 = new gsap.TimelineMax();
		let tween0 = gsap.TweenMax.to('#planet-0', 1, {
			motionPath: {
				path: "#path2",
				autoRotate: false,
				alignOrigin: [0.5, 0.5]
			},
			duration: '100%'
		});
		let tween1 = gsap.TweenMax.to('#planet-1', 1, {
			motionPath: {
				path: "#path1",
				autoRotate: false,
				alignOrigin: [0.5, 0.5]
			},
			duration: '100%'
		});
		let tween2 = gsap.TweenMax.to('#planet-2', 1, {
			motionPath: {
				path: "#path4",
				autoRotate: false,
				alignOrigin: [0.5, 0.5]
			},
			duration: '100%'
		});

		tlSection2.add(tween0).add(tween1).add(tween2);

		new ScrollMagic.Scene({
			triggerElement: '#section-2',
			duration: '100%',
			triggerHook: 'onEnter',
			tweenChanges: false
		})
		.setTween(tlSection2)
		.addTo(controller);
	}
}

function createTimelineSection3() {
	if (ScrollMagic && gsap) {

		let tlSection3 = new gsap.TimelineMax();
		let tween0 = gsap.TweenMax.to('#planet-0', 1, {
			motionPath: {
				path: "#path3",
				autoRotate: false,
				alignOrigin: [0.5, 0.5]
			},
			duration: '100%'
		});
		let tween1 = gsap.TweenMax.to('#planet-1', 1, {
			motionPath: {
				path: "#path2",
				autoRotate: false,
				alignOrigin: [0.5, 0.5]
			},
			duration: '100%'
		});
		let tween2 = gsap.TweenMax.to('#planet-2', 1, {
			motionPath: {
				path: "#path1",
				autoRotate: false,
				alignOrigin: [0.5, 0.5]
			},
			duration: '100%'
		});
		let tween3 = gsap.TweenMax.to('#planet-3', 1, {
			motionPath: {
				path: "#path4",
				autoRotate: false,
				alignOrigin: [0.5, 0.5]
			},
			duration: '100%'
		});

		tlSection3.add(tween0).add(tween1).add(tween2).add(tween3);

		new ScrollMagic.Scene({
			triggerElement: '#section-3',
			duration: '100%',
			triggerHook: 'onEnter',
			tweenChanges: false
		})
		.setTween(tlSection3)
		.addTo(controller);
	}
}

function createTimelineSection4() {
	if (ScrollMagic && gsap) {

		let tlSection4 = new gsap.TimelineMax();
		let tween1 = gsap.TweenMax.to('#planet-1', 1, {
			motionPath: {
				path: "#path3",
				autoRotate: false,
				alignOrigin: [0.5, 0.5]
			},
			duration: '100%'
		});
		let tween2 = gsap.TweenMax.to('#planet-2', 1, {
			motionPath: {
				path: "#path2",
				autoRotate: false,
				alignOrigin: [0.5, 0.5]
			},
			duration: '100%'
		});
		let tween3 = gsap.TweenMax.to('#planet-3', 1, {
			motionPath: {
				path: "#path1",
				autoRotate: false,
				alignOrigin: [0.5, 0.5]
			},
			duration: '100%'
		});
		let tween4 = gsap.TweenMax.to('#planet-4', 1, {
			motionPath: {
				path: "#path4",
				autoRotate: false,
				alignOrigin: [0.5, 0.5]
			},
			duration: '100%'
		});

		tlSection4.add(tween1).add(tween2).add(tween3).add(tween4);

		new ScrollMagic.Scene({
			triggerElement: '#section-4',
			duration: '100%',
			triggerHook: 'onEnter',
			tweenChanges: false
		})
		.setTween(tlSection4)
		.addTo(controller);
	}
}

function createTimelineSection5() {
	if (ScrollMagic && gsap) {

		let tlSection5 = new gsap.TimelineMax();
		
		let tween1 = gsap.TweenMax.to('#planet-3', 1, {
			motionPath: {
				path: "#path2",
				autoRotate: false,
				alignOrigin: [0.5, 0.5]
			},
			duration: '100%'
		});
		
		let tween2 = gsap.TweenMax.to('#planet-2', 1, {
			motionPath: {
				path: "#path3",
				autoRotate: false,
				alignOrigin: [0.5, 0.5]
			},
			duration: '100%'
		});

		tlSection5.add(tween1).add(tween2);

		new ScrollMagic.Scene({
			triggerElement: '#section-5',
			duration: '100%',
			triggerHook: 'onEnter',
			tweenChanges: false
		})
		.setTween(tlSection5)
		.addTo(controller);
	}
}

const PlanetsOrbit = props => {
	const [center, setCenter] = useState({x: 0, y: 0});
	const [radius, setRadius] = useState(100);

	useEffect(() => {
		if (props.cx !== null && props.cy !== null)
			setCenter({x: props.cx, y: props.cy});
	}, [props.cx, props.cy]);

	useEffect(() => {
		if (props.radius !== null)
			setRadius(props.radius);
	}, [props.radius]);

	useEffect(() => {
		if (ScrollMagic && gsap) 
			controller = new ScrollMagic.Controller();

		setTimeout(() => {
			createTimelineSection0();
			createTimelineSection1();
			createTimelineSection2();
			createTimelineSection3();
			createTimelineSection4();
			createTimelineSection5();
		}, 1000);
	}, [createTimelineSection0, createTimelineSection1, createTimelineSection2, createTimelineSection3, createTimelineSection4, createTimelineSection5]);
	
	// divido la circonferenza sempre in 4 parti indifferentemente dal numero di pianeti
	let points = splitCircumference(radius, radius, radius, 4);//props.planets.length);
	let planets = props.planets;

	return (
		<div className="planets-orbit planets-orbit-2" 
			style={{
				height: radius * 2,
				width: radius * 2,
				top: center.y - radius,
				left: center.x - radius,
				...props.style
			}}>
			<svg width="100%" height="100%" viewBox={"0 0 " + radius*2 + " " + radius*2 + ""}>
				{/* bottom left */}
				<path id="path1" fill="none" stroke="white" strokeWidth="0.5" d={"M" + radius + "," + radius*2 + " a" + radius + "," + radius + " 0 0,1 -" + radius + ",-" + radius + ""} />
				{/* top left */}
				<path id="path2" fill="none" stroke="white" strokeWidth="0.5" d={"M0," + radius + " a" + radius + "," + radius + " 0 0,1 " + radius + ",-" + radius + ""} />
				{/* top right */}
				<path id="path3" fill="none" stroke="white" strokeWidth="0.5" d={"M" + radius + ",0 a" + radius + "," + radius + " 0 0,1 " + radius + "," + radius + ""} />
				{/* bottom right */}
				<path id="path4" fill="none" stroke="white" strokeWidth="0.5" d={"M" + radius*2 + "," + radius + " a" + radius + "," + radius + " 0 0,1 -" + radius + "," + radius + ""} />

				{/* <circle id="orbit" cx="20" cy="20" r="20" fill="none" stroke="white" strokeWidth="0.01" /> */}
			</svg>
			{planets.map((p, i) => {
				return (
					<Planet
						{...p}
						id={"planet-" + i}
						key={"planet-" + i}
						style={{
							// top: points[1].y,
							// left: points[1].x,
							// transform: 
							// 	i === 0 ?
							// 	'translate(calc(' + radius + 'px - 50%), calc(' + radius*2 + 'px - 50%))'
							// 	:
							// 	'translate(calc(' + radius*2 + 'px - 50%), calc(' + radius + 'px - 50%))'
						}}
					/>
				);
			})}
		</div>
	);
};

export default PlanetsOrbit;
