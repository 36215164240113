import React from 'react';

import gsap from 'gsap';

import Particles from 'react-particles-js';




/**
 * PROPS PROGETTO
 * 
 */


import './BackgroundStars.scss';

class BackgroundStars extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrolled: false,
            scrolling: false,
        };

        this.modal = null;

        this.handleOnScroll = this.handleOnScroll.bind(this);


    }

    componentDidMount() {
        console.log(gsap);

    }

    componentWillUnmount() {

    }

    handleOnScroll(event) {

    }

    render() {
        return (
            <div className="home">
                <section className="home__background">
                    <Particles
                        style={{
                            width: '100%',
                        }} params={{
                            particles: {
                                number: {
                                    value: 275,
                                    density: {
                                        enable: true,
                                        value_area: 800,
                                    }
                                },
                                
                                line_linked: {
                                    enable: false,
                                },
                                size : {
                                    value : 1,
                                    random : true,
                                    anim : {
                                      enable : false,
                                      speed : 4,
                                      size_min : 0.3,
                                      sync : false
                                   }
                                 },
                                 opacity : {
                                    value : 1,
                                    random : true,
                                    anim : {
                                      enable : true,
                                      speed : 1,
                                      opacity_min : 0,
                                      sync : false
                                   }
                                 },
                                 
                                 move : {
                                    enable : true,
                                    speed : 1,
                                    random : true,
                                    straight : false,
                                    bounce : false,
                                    attract : {
                                      enable : false,
                                      rotateX : 600,
                                      rotateY : 600
                                   }
                                 }
                                 
                            },
                            interactivity : {
                                detect_on :  'canvas' ,
                                events : {
                                  onhover : {
                                    enable : true,
                                  //  mode: 'repulse'
                                 },
                                  onclick : {
                                    enable : true,
                                 },
                                  resize : true
                               },
                                modes : {
                                  grab : {
                                    distance : 10,
                                    
                                 },
                               
                                  repulse : {
                                    distance : 800,
                                    duration : 0.8
                                 },
                                  push : {
                                    particles_nb : 800
                                 },
                                  remove : {
                                    particles_nb : 800
                                 }
                               },
                            },
                        
                            polygon: {
                                enable: true,
                                move: {
                                    radius: 10
                                },
                            },
                            


                        }} height="100vh" width="100vw" />




                </section>


            </div >

        );
    }
}

export default BackgroundStars;
